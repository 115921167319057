import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { message, Modal } from 'ant-design-vue'
import { getToken } from '@/utils/auth'
Vue.use(Modal)

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 1000 * 120 // request timeout
})

// request interceptor -- 请求拦截器
service.interceptors.request.use(
    config => {
        const writeList = ['/login']
        if (getToken()) {
            if (writeList.indexOf(config.url) === -1) {
                config.headers['token'] = getToken()
            }
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor -- 响应拦截器
service.interceptors.response.use(
    response => {
        const data = response.data
        if (data.code === 0) {
            return data
        } else if (data.code === 4001) {
          Modal.confirm({
            title: '登录过期',
            content: '您当前登录状态失效，请重新登录',
            okText: '重新登录',
            okType: 'primary',
            cancelText: '取消',
            centered: true,
            onOk() {
              store.dispatch('user/resetAll').then(() => {
                router.push({ path: '/login' }).then(() => {})
              })
            }
          })
          return data
        } else if (data instanceof Blob) {
            return data
        } else {
            message.error(data.message)
            return data
        }

        // return response
    },
    error => {
        return Promise.reject(error)
    }
)

export default service
