import Vue from 'vue'

// 下载文件
Vue.prototype.$downloadFile = function(name, res) {
  const downloadElement = document.createElement('a')
  downloadElement.href = res
  downloadElement.download = name
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
}

Vue.prototype.$getDownload = function(res) {
  if (res.status === 200) {
    if (!res.headers.filename) {
      this.$antMessage.error('导出失败')
      return
    }
    const blob = new Blob([res.data])
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
    downloadElement.href = href
    downloadElement.download = res.headers.filename ? decodeURIComponent(res.headers.filename) : '未知文件'
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(href)
  } else {
    this.$antMessage.error('导出失败')
  }
}

// 节流技术
let throttleObj = null
Vue.prototype.$throttle = function(time, callBack) {
  if (throttleObj) {
    clearTimeout(throttleObj)
  }
  throttleObj = setTimeout(() => {
    callBack()
  }, time)
}
